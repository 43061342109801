<template>
    <div class="container_detail">
        <div class="header_Css">
            <div @click="toBack"><img src="../assets/back.png" alt=""></div>
            <h2>Compartilhe com um clique e ganhe milhões por mês.</h2>
            <span></span>
        </div>
        <div style="height: 10px;"></div>
        <div class="item2_part2">
            <h4> Conteúdo da atividade</h4>
            <span class="spanCSS">Convide seus amigos para se registrar no jogo através de diversas maneiras: pelo nosso
                site/Facebook/Instagram/TikTok/Telegram/WhatsApp, entre outros. Não há limites! Você pode usufruir de
                altas comissões apostando R$1 ou mais!<span style="color: #ffcc00;">Você também pode obter a maior
                    comissão de 2.5%,</span> em um convite e obter comissão vitalícia! Convide seus amigos para
                participar na <span style="color: #ffcc00;">Win444.COM</span> e compartilhe dessa emoção e a da fortuna
                da diversão online! O sistema liquidará a comissão do dia anterior às 7 da manhã do dia seguinte, todos
                os dias. Como acessar: faça o login na conta → Comissão de Membros → (Minha) Comissão → Coletar Comissão
                / Receber. <br> As taxas de comissão de apostas para Slot, Pescaria e Cartas são as seguintes.</span>
            <table style="width: 100%;">
                <tr style="background: rgb(1 35 70);">
                    <td style="width: 240px;color: #fff;"> Apostas diárias válidas de Membros offline</td>
                    <td style="color: #fff;"> Slot, Pescaria e Cartas</td>
                </tr>
                <tr>
                    <td>1+</td>
                    <td>0.60%</td>
                </tr>
                <tr>
                    <td>500000+</td>
                    <td>0.80%</td>
                </tr>
                <tr>
                    <td>1000000+</td>
                    <td>1.00%</td>
                </tr>
                <tr>
                    <td>5000000+</td>
                    <td>1.50%</td>
                </tr>
                <tr>
                    <td>10000000+</td>
                    <td>2.00%</td>
                </tr>
                <tr>
                    <td>50000000+</td>
                    <td>2.50%</td>
                </tr>
            </table>
            <span> Exemplo: Você tem 100 membros offline, e o valor real total das apostas é estimado em 1 milhão de
                reais por dia. A comissão que você recebe nesse dia é: 1000000×1% = 10000 reais. Uma renda mensal de
                100000 é super fácil de conseguir.</span>
            <span> A taxa de comissão de apostas em Jogo Ao Vivo, Esporte e Loteria são as <a target="_top"
                    style="color: #666;">seguintes:.</a> </span>
            <table style="width: 100%;">
                <tr style="background: rgb(1 35 70);">
                    <td style="width: 240px;color: #fff;"> Apostas diárias válidas de Membros offline</td>
                    <td style="color: #fff;"> Jogo Ao Vivo, Esporte </td>
                </tr>
                <tr>
                    <td>50000+</td>
                    <td>0.05%</td>
                </tr>
                <tr>
                    <td>20,0000+</td>
                    <td>0.10%</td>
                </tr>
                <tr>
                    <td>50,0000+</td>
                    <td>0.15%</td>
                </tr>
                <tr>
                    <td>80,0000+</td>
                    <td>0.20%</td>
                </tr>
                <tr>
                    <td>120,0000+</td>
                    <td>0.25%</td>
                </tr>
                <tr>
                    <td>160,0000+</td>
                    <td>0.30%</td>
                </tr>
                <tr>
                    <td>220,0000+</td>
                    <td>0.35%</td>
                </tr>
                <tr>
                    <td>330,0000+</td>
                    <td>0.40%</td>
                </tr>
            </table>
            <h4>Método de resgate</h4>
            <span> Após as 7 horas no horário de Brasília: Faça o Login na sua conta → clique em Comissão de Membros →
                (Minha) Comissão → Coletar Comissão/ Receber. Caso não tenha recebido no mesmo dia, a comissão será
                acumulada automaticamente para o dia seguinte.</span>
                <h4>Regras do Evento</h4>
                <span>1. A comissão obtida requer pelo menos 1 vez o rollover para retirar;</span><br>
                <span>2. Após as 7 horas no horário de Brasília: Faça o Login na sua conta → clique em Comissão de Membros → (Minha) Comissão → Coletar Comissão/ Receber. Caso não tenha recebido no mesmo dia, a comissão será acumulada automaticamente para o dia seguinte.</span><br>
                <span>3. A participação dos membros nas atividades é contabilizada automaticamente pelo sistema, caso haja alguma objeção, prevalecerá o resultado da análise feita pela Win444.COM;</span><br>
                <span>4. Se você esquecer sua conta/senha de membro, entre em contato com o nosso SAC 24</span>
        </div>

        <div class="qqq">
            <img class="bom_img" src="@/assets/images/home/活动01.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动02.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动03.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动04.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动05.png" alt="">
            <img class="bom_img" src="@/assets/images/home/活动06.png" alt="">
        </div>

    </div>
</template>

<script>


export default {
    data() {
        return {

        }
    },
    methods: {
        toBack() {
            this.$router.push('/home/event')
        }
    }
}
</script>

<style lang="scss" scoped>
.container_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header_Css {
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 100%;

    img {
        cursor: pointer;
    }

    h2 {
        color: #fff;
        font-size: 15px;
        width: 260px;
        text-align: center;
    }

    span {
        color: #feaf75;
        cursor: pointer;
    }
}

.item2_part2 {
    width: 100%;
    background: #000000;
    padding: 0 10px;

    h4 {
        color: #ffcc00;
        font-weight: bold;
        padding-top: 10px;
        /* padding-left: 10px; */
    }

    .spanCSS {
        text-align: justify;
    }

    td {
        border: 1px solid #363636;
        text-align: center;
    }
}

.qqq {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    display: flex;

    .bom_img {
        width: 104px;
        height: 50px;
        margin: 0 5px
    }
}
</style>
